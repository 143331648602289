import { createApi } from "@reduxjs/toolkit/query/react";
import type { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import type { AxiosRequestConfig, AxiosError } from "axios";
import axiosInstance from "../api/axiosInstance";

import { v4 as uuidv4 } from "uuid";
import {
  Conversation,
  ConversationMessage,
  ConversationsResponse,
  CurrentVendorBookingResponse,
  GetListingsResponse,
  Listing,
  ListingImage,
  ListingImageBody,
} from "../types";
import { string } from "yup";
import { getISODatesBetween } from "../utils";
import { Moment } from "moment";

type CustomErrorType = {
  status: number | undefined;
  data: Record<string, any> | string | undefined;
};

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    { data: Record<string, any> | any[] },
    CustomErrorType
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return result.data;
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

// Define our single API slice object
export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: "api",
  // All of our requests will have URLs starting with '/fakeApi'
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}`,
  }),
  tagTypes: [
    "Conversation",
    "Listing",
    "VendorBooking",
    "StockUnitAvailability",
  ],
  // The "endpoints" represent operations and requests for this server
  endpoints(builder) {
    return {
      getCurrentVendorBookings: builder.query<
        CurrentVendorBookingResponse,
        void
      >({
        query: () => ({ url: "vendor-booking/current/", method: "get" }),
      }),
      getUpcomingVendorBookings: builder.query<
        CurrentVendorBookingResponse,
        void
      >({
        // TODO: modify this for the limit to concern orders not bookings
        query: () => ({
          url: "vendor-booking/?upcoming=1&limit=20",
          method: "get",
        }),
      }),
      getVendorOrder: builder.query<Record<string, any>, number>({
        // TODO: modify this for the limit to concern orders not bookings
        query: (orderNumber) => ({
          url: `vendor-booking/?order_number=${orderNumber}`,
          method: "get",
        }),
      }),
      getVendorReservation: builder.query<Record<string, any>, number>({
        // TODO: modify this for the limit to concern orders not bookings
        query: (orderNumber) => ({
          url: `vendor-reservations/?order_number=${orderNumber}`,
          method: "get",
        }),
      }),
      getListings: builder.query<GetListingsResponse, void>({
        query: () => ({
          url: "rental-items/?limit=2000",
          method: "get",
        }),
        providesTags: (result, error, arg) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Listing" as const, id })),
                "Listing",
              ]
            : ["Listing"],
      }),
      editListing: builder.mutation<
        Listing,
        Partial<Listing> & Pick<Listing, "id">
      >({
        query: (data) => ({
          url: `rental-items/${data.id}/`,
          method: "patch",
          data,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "Listing", id: arg.id },
        ],
      }),

      addListingImage: builder.mutation<ListingImage, Blob>({
        query: (imageFile: Blob) => {
          const bodyFormData = new FormData();
          bodyFormData.append("image_file", imageFile, `${uuidv4()}.jpg`);
          console.log("Body form data", { bodyFormData, imageFile });
          return {
            url: `images/`,
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data;",
            },
            data: bodyFormData,
          };
        },
      }),
      getConversations: builder.query<ConversationsResponse, void>({
        query: () => {
          let params: Record<string, any> = {
            limit: 1000,
          };
          return { url: "conversations/", method: "get", params };
        },
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Conversation" as const,
                  id,
                })),
                { type: "Conversation", id: "LIST" },
              ]
            : [{ type: "Conversation", id: "LIST" }],
      }),
      postConversationMessage: builder.mutation<
        ConversationMessage,
        {
          conversationId: string;
          messageBody: string;
        }
      >({
        query: (data) => {
          const { conversationId, messageBody } = data;
          return {
            url: `conversations/${conversationId}/messages/`,
            method: "POST",
            data: {
              messageBody,
            },
          };
        },
        invalidatesTags: (result, error, { conversationId }) => [
          { type: "Conversation", conversationId },
        ],
      }),
      markConversationRead: builder.mutation<
        Conversation,
        Pick<Conversation, "id">
      >({
        query: ({ id }) => ({
          url: `conversations/${id}/read/`,
          method: "PATCH",
        }),
        //invalidatesTags: (result, error, { id }) => [{ type: 'Conversation', id }],
      }),
      // TODO: type this
      getVendorStockUnits: builder.query<any, void>({
        query: () => ({ url: "vendor-stock-units/", method: "get" }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }: { id: string }) => ({
                  type: "StockUnit" as const,
                  id,
                })),
                { type: "StockUnit", id: "LIST" },
              ]
            : [{ type: "StockUnit", id: "LIST" }],
      }),
      // TODO: type this
      getVendorStockUnitAvailability: builder.query<any, Record<string, any>>({
        query: ({ startDateISO, endDateISO }) => {
          let params: Record<string, any> = {
            start_date: startDateISO,
            end_date: endDateISO,
          };
          console.log("Getting more data in API:", startDateISO, endDateISO);
          return { url: "vendor-stock-availabilities/", method: "get", params };
        },
        providesTags: (result) =>
          result
            ? [
                // TODO: type this and add start/end dates
                ...result.map(
                  (singleUnitAvailability: Record<string, any>) => ({
                    type: "StockUnitAvailability" as const,
                    id: singleUnitAvailability.id,
                  }),
                ),
                // TODO:  add start/end dates to both tags
                { type: "StockUnitAvailability", id: "LIST" },
              ]
            : [{ type: "StockUnitAvailability", id: "LIST" }],
      }),
      // TODO: type this
      getVendorBookingAvailabilities: builder.query<any, Record<string, any>>({
        query: ({ startDateISO, endDateISO, orderItems }) => {
          let params: Record<string, any> = {
            start_date: startDateISO.format("YYYY-MM-DD"),
            end_date: endDateISO.format("YYYY-MM-DD"),
            order_items_json: JSON.stringify(
              orderItems.map((orderItem: Record<string, any>) => {
                let selectedOptions;

                if (orderItem.option) {
                  const parts = orderItem.option.split(":");
                  selectedOptions = {
                    [parts[0].trim()]: parts[1].trim(),
                  };
                }

                return {
                  itemId: orderItem.id,
                  listingId: orderItem.rentalItemId,
                  quantity: orderItem.quantity,
                  selectedOptions,
                };
              }),
            ),
          };
          console.log("Getting vendor booking availabilities:", params);
          return {
            url: "vendor-booking-availabilities/",
            method: "get",
            params,
          };
        },
      }),
      postVendorReservation: builder.mutation<
        // TODO: type this
        Record<string, any>,
        {
          startDate: Moment;
          endDate: Moment;
          clientName: string;
          clientContact: string;
          notes?: string;
          orderItems: Record<string, any>[];
        }
      >({
        query: (data) => {
          const {
            startDate,
            endDate,
            clientName,
            clientContact,
            notes,
            orderItems,
          } = data;
          return {
            url: `vendor-reservations/`,
            method: "POST",
            data: orderItems.map((orderItem) => {
              let selectedOptions;

              if (orderItem.option) {
                const parts = orderItem.option.split(":");
                selectedOptions = {
                  [parts[0].trim()]: parts[1].trim(),
                };
              }

              return {
                listingId: orderItem.rentalItemId,
                itemId: orderItem.id,
                clientName,
                clientContact,
                notes,
                quantity: orderItem.quantity,
                reservationDates: getISODatesBetween(startDate, endDate),
                selectedOptions,
              };
            }),
          };
        },
        invalidatesTags: (result, error, {}) => [
          { type: "VendorBooking", id: "LIST" },
          { type: "StockUnitAvailability", id: "LIST" },
        ],
      }),
    };
  },
});

export const {
  useGetCurrentVendorBookingsQuery,
  useGetUpcomingVendorBookingsQuery,
  useGetListingsQuery,
  useGetConversationsQuery,
  useMarkConversationReadMutation,
  usePostConversationMessageMutation,
  useEditListingMutation,
  useAddListingImageMutation,
  useGetVendorStockUnitsQuery,
  useGetVendorStockUnitAvailabilityQuery,
  usePostVendorReservationMutation,
  useGetVendorBookingAvailabilitiesQuery,
  useGetVendorOrderQuery,
  useGetVendorReservationQuery,
} = apiSlice;
